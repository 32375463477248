<template>
  <div>
    <b-overlay :show="busy" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm" :opacity="0.95">
      <template #overlay>
        <div class="text-center">
          <b-spinner :variant="'primary'" class="mr-1" type="grow" />
          <p id="cancel-label">
            Gerald just back from his unannounced coffee break.
            <br />
            Please wait while he generates your copy right now
          </p>
        </div>
      </template>

      <b-card no-body class="mb-0" v-if="permissions">
        <!-- card header -->
        <!-- <b-card-header header-tag="nav" class="pb-0 mb-0">
          <b-row style="width: 100%">
            <b-col cols="12" class="d-flex align-items-center justify-content-center">
              <h5>Listing Copy Writing - Powered by AI</h5>
            </b-col>
          </b-row>
        </b-card-header> -->
        <b-card-body class="text-center">
          <div v-if="permissions.views.includes('listing_copy_writing')">
            <b-row class="m-1 p-1 forms-row">
              <b-col cols="12" class="text-left">
                <h3>Start</h3>
                <h5 class="mb-1">Complete the below inputs to generate your listing copy</h5>
              </b-col>
              <b-col cols="12" md="7">
                <b-row>
                  <!-- property fields -->
                  <b-col cols="11" md="7">
                    <div class="d-flex flex-column align-items-baseline justify-content-center">
                      <h6 class="text-left">Property Information</h6>

                      <!-- address field -->
                      <!-- <label>Address</label> -->
                      <div class="w-100 d-flex flex-row align-items-baseline justify-content-start">
                        <vue-autosuggest
                          v-model="property.address"
                          :input-props="{
                            id: 'autosuggest_address',
                            class: 'form-control vas',
                            placeholder: 'Type to get address suggestions',
                            disabled: false,
                          }"
                          :suggestions="[{ data: suggestions }]"
                          @input="getsuggestions('address')"
                          @selected="(s) => set_autosuggest_value(s, 'address')"
                          :limit="20"
                          component-attr-class-autosuggest-results-container="sidebar-autosuggest"
                        >
                          <template slot-scope="{ suggestion }">
                            <span>{{ suggestion.item.address }}</span>
                          </template>
                        </vue-autosuggest>
                        <b-form-checkbox v-model="include_field.address" style="margin-left: 14px; padding-right: 8px"></b-form-checkbox>
                      </div>

                      <!-- with prop prepend  -->
                      <!-- <b-input-group prepend="?">
                        <b-form-input placeholder="Address" v-model="property.address" />
                      </b-input-group> -->

                      <b-input-group>
                        <b-input-group-prepend
                          is-text
                          v-b-tooltip:hover.v-primary="{
                            title: `The configuration is the number of bedrooms/bathrooms/living/car spaces. e.g 4 beds, 2.5 baths, 2 living, 2 car`,
                            placement: 'bottom',
                          }"
                        >
                          <feather-icon icon="EyeIcon" />
                        </b-input-group-prepend>
                        <b-form-input placeholder="Configuration" v-model="property.configuration" />
                        <b-input-group-append is-text v-b-tooltip:hover.bottom.v-primary.title="'Exclude value from results'">
                          <b-form-checkbox v-model="include_field.configuration"></b-form-checkbox>
                        </b-input-group-append>
                      </b-input-group>

                      <!-- append and pepend -->
                      <b-input-group>
                        <b-input-group-prepend
                          is-text
                          v-b-tooltip:hover.v-primary="{
                            title: `Selling price of property`,
                            placement: 'bottom',
                          }"
                        >
                          <feather-icon icon="EyeIcon" />
                        </b-input-group-prepend>
                        <b-form-input placeholder="Price" v-model="property.price" />
                        <b-input-group-append is-text v-b-tooltip:hover.bottom.v-primary.title="'Exclude price from results'">
                          <b-form-checkbox v-model="include_field.price"></b-form-checkbox>
                        </b-input-group-append>
                      </b-input-group>

                      <b-input-group>
                        <b-input-group-prepend
                          is-text
                          v-b-tooltip:hover.v-primary="{
                            title: `Total lot size of property`,
                            placement: 'bottom',
                          }"
                        >
                          <feather-icon icon="EyeIcon" />
                        </b-input-group-prepend>
                        <b-form-input placeholder="Lot Size" v-model="property.lot_size" type="number" />
                        <b-input-group-append is-text>sqm</b-input-group-append>
                        <b-input-group-append is-text v-b-tooltip:hover.bottom.v-primary.title="'Exclude lot size from results'">
                          <b-form-checkbox v-model="include_field.lot_size"></b-form-checkbox>
                        </b-input-group-append>
                      </b-input-group>

                      <b-input-group>
                        <b-input-group-prepend
                          is-text
                          v-b-tooltip:hover.v-primary="{
                            title: `Home / Structure size of property`,
                            placement: 'bottom',
                          }"
                        >
                          <feather-icon icon="EyeIcon" />
                        </b-input-group-prepend>
                        <b-form-input placeholder="Home Size" v-model="property.home_size" type="number" />
                        <b-input-group-append is-text>sqm</b-input-group-append>
                        <b-input-group-append is-text v-b-tooltip:hover.bottom.v-primary.title="'Exclude lot size from results'">
                          <b-form-checkbox v-model="include_field.home_size"></b-form-checkbox>
                        </b-input-group-append>
                      </b-input-group>

                      <b-input-group v-for="(f, index) of property.other_features" :key="index">
                        <b-form-input placeholder="Feature" v-model="property.other_features[index]" />
                        <b-button class="" variant="flat-danger" @click="property.other_features.splice(index, 1)" size="md">
                          <feather-icon icon="MinusIcon" />
                        </b-button>
                      </b-input-group>
                    </div>
                  </b-col>
                  <!-- Add field buttons -->
                  <b-col cols="1" class="d-flex align-items-end justify-content-left mb-1">
                    <b-button class="mx-0" variant="flat-primary" @click="property.other_features.push('')" size="md">
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                  </b-col>
                  <!-- settings -->
                  <b-col cols="11" md="4" class="py-0 my-0">
                    <h6 class="text-left">Settings</h6>
                    <div class="d-flex flex-column align-items-baseline justify-content-between text-left">
                      <!-- length -->
                      <div class="w-100 d-flex align-items-baseline justify-content-between">
                        <label class="mr-1" v-b-tooltip:hover.bottom.v-primary.title="'Length of generated copy in words'">Length</label>
                        <b-form-spinbutton v-model="settings.length" min="25" max="3000" step="25" inline />
                      </div>
                      <!-- complexity -->
                      <div class="w-100 d-flex align-items-baseline justify-content-between">
                        <label class="mr-1" v-b-tooltip:hover.bottom.v-primary.title="'Variety of words'">Complexity</label>
                        <b-form-spinbutton v-model="settings.complexity" min="0" max="10" step="0.5" inline />
                      </div>
                      <!-- enthusiasm -->
                      <div class="w-100 d-flex align-items-baseline justify-content-between">
                        <label class="mr-1" v-b-tooltip:hover.bottom.v-primary.title="'Gerald\' Caffeine Intake = Randomness'">GCI</label>
                        <b-form-spinbutton v-model="settings.enthusiasm" min="0" max="10" step="0.5" inline />
                      </div>
                      <!-- action buttons -->
                      <div class="w-100 d-flex flex-column align-items-baseline justify-content-end my-1 button-div">
                        <b-button variant="outline-primary" @click="submit_request()" size="sm" block>
                          <feather-icon icon="CheckIcon" class="mr-25" />
                          <span class="align-middle">Generate</span>
                        </b-button>
                        <b-button variant="outline-primary" @click="submit_request()" size="sm" block>
                          <feather-icon icon="RepeatIcon" class="mr-25" />
                          <span class="align-middle">Regenerate</span>
                        </b-button>
                        <b-button variant="outline-primary" @click="setDefaults()" size="sm" block>
                          <feather-icon icon="RefreshCcwIcon" class="mr-25" />
                          <span class="align-middle">Start New</span>
                        </b-button>
                        <b-button variant="outline-primary" @click="setDefaultSettings()" size="sm" block>
                          <feather-icon icon="RefreshCcwIcon" class="mr-25" />
                          <span class="align-middle">Reset Settings</span>
                        </b-button>
                      </div>
                    </div>
                  </b-col>
                  <!-- actions -->
                  <b-col cols="12"></b-col>
                  <!-- history section -->
                  <b-col cols="12">
                    <h3 class="mt-1 text-left">History</h3>
                    <h5 class="text-left">See below for your listing history</h5>
                    <b-table-simple hover small caption-top responsive>
                      <b-thead head-variant="dark">
                        <b-tr>
                          <b-th class="text-center">Date</b-th>
                          <b-th class="text-center">History</b-th>
                        </b-tr>
                      </b-thead>
                      <b-tbody>
                        <template v-if="Object.keys(request).length">
                          <template v-for="[key, value] of Object.entries(request)">
                            <b-tr :key="key">
                              <b-th class="text-left capitalize-text">{{ key }}</b-th>
                              <b-td class="text-left">{{ value }}</b-td>
                            </b-tr>
                          </template>
                        </template>
                        <template v-else>
                          <b-tr>
                            <b-td class="text-center" colspan="2">No history found for this account</b-td>
                          </b-tr>
                        </template>
                      </b-tbody>
                    </b-table-simple>
                  </b-col>
                </b-row>
              </b-col>

              <b-col cols="12" md="5">
                <h6 class="text-left">Result</h6>
                <div class="d-flex align-items-baseline justify-content-between">
                  <!-- with prop append -->
                  <b-input-group>
                    <b-form-textarea rows="20" v-model="result" />
                  </b-input-group>
                </div>
                <div class="d-flex align-items-baseline justify-content-end m-0 p-0">
                  <b-button class="mx-0" variant="flat-primary" @click="copyResult()" size="large" :disabled="!result">
                    <feather-icon icon="CopyIcon" />
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <!-- <b-col cols="6">History</b-col>
            <b-col cols="6">Nothing</b-col> -->
            </b-row>
          </div>
          <div v-else class="d-flex justify-content-center align-items-center">Kindly upgrade to get access to this feature</div>
        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueAutosuggest } from "vue-autosuggest";
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  //  form imports
  BFormTextarea,
  BInputGroup,
  BFormGroup,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormSpinbutton,
  BButton,
  BOverlay,

  //   table imports
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  VBTooltip,
  BFormCheckbox,
  BSpinner,
} from "bootstrap-vue";
export default {
  name: "CopyWriting",
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    //form imports
    BFormTextarea,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormSpinbutton,
    BButton,

    // components
    VueAutosuggest,
    BOverlay,

    // table imports
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    VBTooltip,
    BFormCheckbox,
    BSpinner,
  },

  data() {
    return {
      permissions: null,
      settings: {
        length: 450,
        complexity: 10,
        enthusiasm: 6,
      },
      property: {
        address: null,
        configuration: null,
        price: null,
        lot_size: null,
        home_size: null,
        other_features: [],
      },
      include_field: {
        address: true,
        configuration: true,
        price: true,
        lot_size: true,
        home_size: true,
        other_features: true,
      },
      result: "",
      busy: false,
      suggestions: [],
      request: {},
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  async mounted() {
    try {
      await this.initialize();
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    async initialize() {
      try {
        this.permissions = await this.$store.dispatch("get_permissions");
      } catch (error) {
        console.log(error);
      }
    },

    async submit_request() {
      try {
        if (this.busy) return;
        this.busy = true;
        // if (!this.property.address) throw new Error("Please provide a valid address");
        // return console.log(this.property);
        let property = {};
        for (let [k, v] of Object.entries(this.property)) {
          if (v && v.length && this.include_field[k]) {
            if (["string", "number"].includes(typeof v)) property[k] = v;
            else if (Array.isArray(v))
              property[k] = Object.assign(
                [],
                v.filter((a) => a)
              );
          }
        }

        console.log(property);
        console.log(this.settings);
        if (!Object.keys(property).length) throw new Error("Atleast 1 feature should be given");

        await new Promise((r) => setTimeout(r, 200));
        let res = await this.$axios.post(`${this.$API_BASE_LISTINGS}/getcopywritedata`, {
          settings: this.settings,
          property: property,
        });
        console.log(res.data);
        this.busy = false;
        // console.log(res.data);
        if (!res.data.success) throw new Error(res.data.error);
        this.result = res.data.result.trim();
        this.request = res.data.options;
      } catch (error) {
        this.busy = false;
        console.log(error);
        this.showToast("danger", "Error", error.message ? error.message : "Response could not be generated");
      }
    },
    setDefaults() {
      this.settings = {
        length: 450,
        complexity: 10,
        enthusiasm: 6,
      };
      this.property = {
        address: null,
        configuration: null,
        price: null,
        lot_size: null,
        home_size: null,
        other_features: [],
      };
      this.result = "";
      this.request = {};
    },
    setDefaultSettings() {
      this.settings = {
        length: 450,
        complexity: 10,
        enthusiasm: 6,
      };
    },
    showToast(variant = "info", title = "Info", text = "") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: "BellIcon",
          text,
          variant,
        },
      });
    },
    add_feature() {
      this.property.other_features.push({ key: "", value: "" });
    },
    async getsuggestions(k) {
      try {
        await new Promise((t) => setTimeout(t, 100));
        if (!k || this.property[k].length < 5) return;
        // this.busy = true;
        let res = await this.$axios.post(`${this.$API_BASE_LISTINGS}/getsuggestions`, { key: k, address: this.property[k] });
        // this.busy = false;
        if (!res.data.success) throw new Error("Could not get suggestions");
        this.suggestions = [];
        this.suggestions = res.data.suggestions;
      } catch (error) {
        // this.busy = false;
        console.log(error);
      }
    },
    set_autosuggest_value(s, k) {
      try {
        if (k == "address" && s.item && Object.keys(s.item.addressComponents).length) {
          let ac = s.item.addressComponents;
          this.property[k] = `${ac.streetNumber} ${ac.streetName} ${ac.streetTypeLong}, ${ac.suburb}, ${ac.state}, ${ac.postCode}`;
          this.property[k] = ac.unitNumber ? ac.unitNumber + "/" + this.property[k] : this.property[k];
        } else {
          // requires handling if any other field added for auto suggestion
          this.property[k] = s.item;
        }
      } catch (error) {
        console.log(error);
        this.property[k] = "";
      }
    },
    async copyResult() {
      try {
        await navigator.clipboard.writeText(this.result);
        this.showToast("success", "Text Copied", "");
      } catch (error) {
        console.log(error);
        this.showToast("danger", "Error", "Text could not be copied to clipboard, kindly try doing it manually");
      }
    },
  },
};
</script>

<style lang="scss">
// autosuggest section
#autosuggest_address {
  width: 100%;
  padding: 0.5rem;
  padding-left: 1rem;
}

.sidebar-autosuggest ul {
  width: 100%;
  color: rgba(30, 39, 46, 1);
  list-style: none;
  margin: 0;
  padding: 0.5rem 0 0.5rem 0;
}
.sidebar-autosuggest li {
  margin: 0 0 0 0;
  border-radius: 5px;
  padding: 0.75rem 0 0.75rem 0.75rem;
  display: flex;
  align-items: center;
}
.sidebar-autosuggest li:hover {
  cursor: pointer;
}

.autosuggest-container {
  display: flex;
  justify-content: center;
  width: 280px;
}

#autosuggest {
  width: 100%;
  display: block;
}
.sidebar-autosuggest .autosuggest__results-item--highlighted {
  background-color: rgba(80, 140, 253, 0.2);
}
.sidebar-autosuggest .autosuggest__results {
  position: absolute;
  width: 100%;
  overflow-y: auto;
  max-height: 50vh;
  z-index: 999;
  background-color: white;
  border: 1px solid rgb(202, 199, 199);
}
.button-div button {
  margin: 0px !important;
}
.forms-row .input-group,
.forms-row #autosuggest input,
.forms-row .b-form-spinbutton,
.button-div button {
  margin-bottom: 3px !important;
}
.button-div button {
  width: 100%;
}

.autosuggest-checkbox {
  width: 12.5%;
  // padding: 0.438rem 1rem;
  margin-bottom: 0;
  text-align: center;
  background-color: white;
  // border: 1px solid #d8d6de;
  // border-radius: 0.357rem;
}
.autosuggest-checkbox input {
  float: center;
}
// .vas{
//   width: 90% !important;
//   margin-right: 20px !important;
// }
</style>
